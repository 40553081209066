export default function AWSCertifcateManagerIcon() {
    return (
        <svg height="40" width="40" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="0%" y1="100%" x2="100%" y2="0%"
                    id="Arch_AWS-Certificate-Manager_32_svg__a">
                    <stop stopColor="#BD0816" offset="0%"></stop>
                    <stop stopColor="#FF5252" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h40v40H0z" fill="url(#Arch_AWS-Certificate-Manager_32_svg__a)"></path>
                <path
                    d="M17.832 19.63c.133.12.19.3.152.476l-.513 2.362 2.27-1.391a.496.496 0 01.52 0l2.252 1.388-.498-2.367a.5.5 0 01.15-.47l2.014-1.84-2.665-.515a.496.496 0 01-.352-.27l-1.166-2.387-1.194 2.394a.497.497 0 01-.35.265l-2.657.518 2.037 1.838zm-1.58 3.765l.701-3.22-2.562-2.312a.498.498 0 01.237-.856l3.393-.66 1.534-3.075c.085-.17.286-.306.447-.276.19.001.362.11.445.28l1.5 3.068 3.39.656a.498.498 0 01.241.854l-2.533 2.316.68 3.223a.498.498 0 01-.748.525L20 22.083l-3.002 1.84a.492.492 0 01-.548-.018.495.495 0 01-.198-.51zm16.727-13.3l-.003-2.101H6.994v2.101h25.985zM34 32.502a.496.496 0 01-.497.498h-3.566v-.994h3.07L32.98 11.09H6.994v20.917h18.968V33H6.497a.5.5 0 01-.352-.145.5.5 0 01-.145-.352V7.497C6 7.223 6.222 7 6.497 7H33.47c.276 0 .497.223.497.497L34 32.502zm-22.942-2.527h3.974v-.993h-3.974v.993zm5.962.023h6.955v-.993H17.02v.993zm-5.962-3h3.974v-.993h-3.974v.994zm5.962 0h10.93v-.993H17.02v.994z"
                    fill="#FFF"></path>
            </g>
        </svg>);
}