export default function AWSCognitoIcon() {
    return (
        <svg height="40" width="40" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="Arch_Amazon-Cognito_32_svg__a">
                    <stop stopColor="#BD0816" offset="0%"></stop>
                    <stop stopColor="#FF5252" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h40v40H0z" fill="url(#Arch_Amazon-Cognito_32_svg__a)"></path>
                <path
                    d="M9 17h6v-1H9v1zm22-4V9.3c0-.717-.465-1.3-1.037-1.3H8.037C7.475 8 7 8.596 7 9.3V13h11V9.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V13h1zm-9 1c0 1.104.897 2 2 2s2-.896 2-2c0-1.103-.897-2-2-2s-2 .897-2 2zm9 0h-1v5h-1v-9H19v8.696a5.52 5.52 0 013.097-2.396A2.981 2.981 0 0121 14c0-1.654 1.346-3 3-3s3 1.346 3 3c0 .966-.467 1.817-1.177 2.366 1.219.421 2.285 1.243 2.958 2.354l-.855.518a4.595 4.595 0 00-2.633-2.02 3.37 3.37 0 01-2.836 0A4.542 4.542 0 0019.216 21H23v1h-4.5a.5.5 0 01-.5-.5V14H7v9.701C7 24.405 7.475 25 8.037 25H21v1H8.037C6.914 26 6 24.969 6 23.701V9.3C6 8.032 6.914 7 8.037 7h21.926C31.086 7 32 8.032 32 9.3V21h-.999L31 14zm2 14c0 2.757-2.243 5-5 5s-5-2.243-5-5c0-2.756 2.243-5 5-5s5 2.244 5 5zm1 0c0-3.308-2.691-6-6-6s-6 2.692-6 6c0 3.309 2.691 6 6 6s6-2.691 6-6zm-6.138 1.862l3.714-3.713-.707-.707-3.36 3.36-1.514-1.514-.707.707 1.867 1.867a.5.5 0 00.707 0zM14 20h2v-1h-2v1zm-5 0h4v-1H9v1z"
                    fill="#FFF"></path>
            </g>
        </svg>);
}