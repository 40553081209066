export default function ReactIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41px" height="36px" viewBox="0 0 41 36" version="1.1">
            <g id="surface1">
                <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(38.039216%,85.490196%,98.431373%)', fillOpacity: 1 }} d="M 24.070312 18 C 24.070312 19.992188 22.472656 21.605469 20.5 21.605469 C 18.527344 21.605469 16.929688 19.992188 16.929688 18 C 16.929688 16.007812 18.527344 14.394531 20.5 14.394531 C 22.472656 14.394531 24.070312 16.007812 24.070312 18 Z M 24.070312 18 " />
                <path style={{ fill: 'none', strokeWidth: 1, strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(38.039216%,85.490196%,98.431373%)', strokeOpacity: 1, strokeMiterlimit: 4 }} d="M 10.999705 0 C 10.999705 -2.320349 6.07607 -4.201053 0 -4.201053 C -6.07607 -4.201053 -10.999705 -2.320349 -10.999705 0 C -10.999705 2.320349 -6.07607 4.201053 0 4.201053 C 6.07607 4.201053 10.999705 2.320349 10.999705 0 Z M 10.999705 0 " transform="matrix(1.742234,0,0,1.759232,20.5,18)" />
                <path style={{ fill: 'none', strokeWidth: 1, strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(38.039216%,85.490196%,98.431373%)', strokeOpacity: 1, strokeMiterlimit: 4 }} d="M 10.999375 -0.000190473 C 11.000054 -2.319494 6.075633 -4.199522 -0.000772347 -4.199715 C -6.075255 -4.198799 -11.00062 -2.320223 -10.999375 0.000190473 C -11.000054 2.319494 -6.075633 4.199522 0.000772347 4.199715 C 6.075255 4.198799 11.00062 2.320223 10.999375 -0.000190473 Z M 10.999375 -0.000190473 " transform="matrix(0.871117,1.523539,-1.508819,0.879616,20.5,18)" />
                <path style={{ fill: 'none', strokeWidth: 1, strokeLinecap: 'butt', strokeLinejoin: 'miter', stroke: 'rgb(38.039216%,85.490196%,98.431373%)', strokeOpacity: 1, strokeMiterlimit: 4 }} d="M 10.999375 0.000190473 C 11.00062 -2.320223 6.075255 -4.198799 0.000772347 -4.199715 C -6.075633 -4.199522 -11.000054 -2.319494 -10.999375 -0.000190473 C -11.00062 2.320223 -6.075255 4.198799 -0.000772347 4.199715 C 6.075633 4.199522 11.000054 2.319494 10.999375 0.000190473 Z M 10.999375 0.000190473 " transform="matrix(-0.871117,1.523539,-1.508819,-0.879616,20.5,18)" />
            </g>
        </svg>);
}