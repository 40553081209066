export default function AWSIAMICon() {
    return (
        <svg height="40" width="40" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="0%" y1="100%" x2="100%" y2="0%"
                    id="Arch_AWS-Identity-and-Access-Management_32_svg__a">
                    <stop stopColor="#BD0816" offset="0%"></stop>
                    <stop stopColor="#FF5252" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h40v40H0z" fill="url(#Arch_AWS-Identity-and-Access-Management_32_svg__a)">
                </path>
                <path
                    d="M7 30h26V11H7v19zm27-19.5v20a.5.5 0 01-.5.5h-27a.5.5 0 01-.5-.5v-20a.5.5 0 01.5-.5h27a.5.5 0 01.5.5zM22 24h8v-1h-8v1zm6-3h3v-1h-3v1zm-6 0h4v-1h-4v1zm-7 2.5a.5.5 0 10-1.002.002A.5.5 0 0015 23.5zm1 0c0 .651-.419 1.201-1 1.408V26h-1v-1.092c-.581-.207-1-.757-1-1.408 0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5zm-6 3.496l8.997.004.001-2H17v-1h1.998l.001-1H17v-1h1.999L19 20.004 10.003 20 10 26.996zM11 19l6.998.003v-3.426c0-.818-.666-1.414-1.063-1.696a4.362 4.362 0 00-2.435-.78h-.001c-1.765 0-3.497 1.225-3.498 2.474L11 19zm-2 8.496l.003-7.996c0-.133.053-.26.146-.354A.504.504 0 019.503 19H10l.001-3.425c.001-1.989 2.375-3.474 4.498-3.474h.001a5.33 5.33 0 013.014.964c.958.681 1.485 1.573 1.484 2.513v3.426h.502a.5.5 0 01.5.5l-.003 7.996c0 .133-.053.26-.146.354a.504.504 0 01-.354.146L9.5 27.996a.5.5 0 01-.5-.5zM29 18h2v-1h-2v1zm-7 0h6v-1h-6v1z"
                    fill="#FFF"></path>
            </g>
        </svg>);
}